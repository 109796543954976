/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext, PropsWithChildren } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import {
  Home,
  Extension,
  Map,
  LibraryBooks,
  Add,
  Cloud,
  List,
  AddAlert,
  Timeline,
  LibraryAddCheck,
  Email,
  VerticalSplit,
  Forum,
  LocalLibrary,
  School,
  Code,
  CameraFront,
  Assessment,
  Security,
  WebAsset,
} from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { NavLink } from 'react-router-dom';
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import {
  SidebarSearchModal
} from '@backstage/plugin-search';
import {
  Sidebar,
  SidebarPage,
  sidebarConfig,
  SidebarContext,
  SidebarItem,
  SidebarDivider,
  SidebarSpace,
  SidebarGroup,
  SidebarScrollWrapper,
} from '@backstage/core-components';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useContext(SidebarContext);

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      {/* Global nav, not org-specific */}
      <SidebarItem icon={Home} to="catalog" text="Home" />
      <SidebarItem icon={Extension} to="api-docs" text="APIs" />
      <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
      <SidebarItem icon={Add} to="create" text="Create..." />
      {/* End global nav */}
      <SidebarDivider />
      <SidebarScrollWrapper>
        <SidebarItem icon={Map} to="tech-radar" text="Tech Radar" />
      </SidebarScrollWrapper>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarScrollWrapper>
        <SidebarItem icon={Cloud} to="/azure" text="Azure Portal" />
        <SidebarItem icon={Cloud} to="/aws" text="AWS" />
        <SidebarItem icon={List} to="/logz" text="Logz.io" />
        <SidebarItem icon={List} to="/sentry" text="Sentry.io" />
        <SidebarItem icon={AddAlert} to="/opsgenie" text="Opsgenie" />
        <SidebarItem icon={Timeline} to="/grafana" text="Grafana" />
        <SidebarItem icon={VerticalSplit} to="/mongo" text="MongoDb" />
        <SidebarItem icon={VerticalSplit} to="/elastic" text="Elastic" />
        <SidebarItem icon={LibraryAddCheck} to="/sonar" text="SonarCloud" />
        <SidebarItem icon={Email} to="/Sengrid" text="Sengrid" />
        <SidebarItem icon={CameraFront} to="/SauceLabs" text="SauceLabs" />
        <SidebarItem icon={Assessment} to="/Mixpanel" text="Mixpanel" />
        <SidebarItem icon={Code} to="/github" text="GitHub" />
        <SidebarItem icon={Map} to="/jira" text="Jira" />
        <SidebarItem icon={School} to="/confluence" text="Confluence" />
        <SidebarItem icon={Forum} to="/Slack" text="Slack" />
        <SidebarItem icon={LocalLibrary} to="/Pluralsight" text="Pluralsight" />
        <SidebarItem icon={Security} to="/iso" text="ISO - ISMS" />
        <SidebarItem icon={WebAsset} to="/GoDaddy" text="GoDaddy" />
        <SidebarItem icon={Map} to="/JetBrains" text="JetBrains" />
        <SidebarItem icon={Map} to="/wiz" text="Wiz" />
        <SidebarItem icon={Map} to="/veracode" text="Veracode" />
        <SidebarItem icon={Map} to="/miro" text="Miro" />
        <SidebarItem icon={Map} to="/dotcom" text="DotCom Monitor" />
      </SidebarScrollWrapper>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarSettings />
    </Sidebar>
    {children}
  </SidebarPage>
);
