import React from 'react';
import { Navigate, Route } from 'react-router';

import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { orgPlugin } from '@backstage/plugin-org';
import { apis, identityServerSSO } from './apis';

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    // bind(apiDocsPlugin.externalRoutes, {
    //   createComponent: scaffolderPlugin.routes.root,
    // });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => (
      <SignInPage
          {...props}
          auto
          providers={[
            {
              id: 'sso-auth-provider',
              title: 'Lobster access identity server',
              message: 'Sign in using Ecolab',
              apiRef: identityServerSSO ,
            }]
         }
          align="center"
      />
   ),
},
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

function RedirectToService() {
  window.location.href = 'https://lobster.tech' + window.location.pathname;
  return null;
}


const routes = (
  <FlatRoutes>
    <Navigate key="/" to="catalog" />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/catalog-import" element={<CatalogImportPage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/azure" element={<RedirectToService />} />
    <Route path="/aws" element={<RedirectToService />} />
    <Route path="/logz" element={<RedirectToService />} />
    <Route path="/sentry" element={<RedirectToService />} />
    <Route path="/opsgenie" element={<RedirectToService />} />
    <Route path="/grafana" element={<RedirectToService />} />
    <Route path="/mongo" element={<RedirectToService />} />
    <Route path="/elastic" element={<RedirectToService />} />
    <Route path="/sonar" element={<RedirectToService />} />
    <Route path="/Sengrid" element={<RedirectToService />} />
    <Route path="/SauceLabs" element={<RedirectToService />} />
    <Route path="/Mixpanel" element={<RedirectToService />} />
    <Route path="/github" element={<RedirectToService />} />
    <Route path="/jira" element={<RedirectToService />} />
    <Route path="/confluence" element={<RedirectToService />} />
    <Route path="/Slack" element={<RedirectToService />} />
    <Route path="/Pluralsight" element={<RedirectToService />} />
    <Route path="/iso" element={<RedirectToService />} />
    <Route path="/GoDaddy" element={<RedirectToService />} />
    <Route path="/JetBrains" element={<RedirectToService />} />
    <Route path="/wiz" element={<RedirectToService />} />
    <Route path="/veracode" element={<RedirectToService />} />
    <Route path="/miro" element={<RedirectToService />} />
    <Route path="/dotcom" element={<RedirectToService />} />

  </FlatRoutes>
);


const App = () => (
  <AppProvider>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </AppProvider>
);

export default App;
